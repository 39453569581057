<template>
  <div class='lg:mt-8 px-2 lg:px-0'>
    <section class='py-8 flex flex-col lg:flex-row justify-start gap-x-4 items-start'>
      <div class='flex flex-col-reverse lg:flex-row justify-start lg:justify-between items-start w-full lg:w-2/5 flex-grow-0 flex-shrink-0'>
        <h1 class='text-2xl font-semibold text-gray-900 pl-2 lg:pl-0 my-4'>행사장 안내</h1>
        <img 
          src='https://d3r2ol85dktaw0.cloudfront.net/conferences/ksso2022/images/walkerhill_logo.jpg'
          class='image-link block h-auto lg:h-48 w-full lg:w-48 border bg-white p-6 hover:shadow-md cursor-pointer'
          @click='imageLink("logo")' />
      </div>
      <div class=''>
        <h3 class='text-lg font-semibold mb-2 px-2'>그랜드 워커힐 서울</h3>
        <a 
          href='https://www.walkerhill.com/grandwalkerhillseoul/' 
          target='_blank' 
          class='flex flex-row items-center justify-start gap-x-2 py-1 px-2 hover:bg-gray-100'>
          <globe-alt-icon class='h-5'/>
          <span
            class='block'>
            홈페이지 바로가기
          </span>
        </a>
        <a 
          href='tel:+82-1670-0005' 
          class='flex flex-row items-center justify-start gap-x-2 py-1 px-2 hover:bg-gray-100'>
          <phone-icon class='h-5'/>
          <span class=''>1670-0005</span>
        </a>
        <a href='https://www.google.com/maps/place/Grand+Walkerhill+Seoul/@37.5512228,127.1027551,15z/data=!4m8!3m7!1s0x357cbaba125163d7:0xac346f03d617148e!5m2!4m1!1i2!8m2!3d37.5552126!4d127.1108905'
          target='_blank'
          class='flex flex-row items-center justify-start gap-x-2 py-1 px-2 hover:bg-gray-100'>
          <location-marker-icon class='h-5'/>
          <span class=''>서울특별시 광진구 워커힐로 177 워커힐호텔</span>
        </a>
      </div>
    </section>
    <section class='py-8 flex flex-col lg:flex-row justify-start gap-x-4 items-start'>
      <div class='flex flex-col-reverse lg:flex-row justify-start lg:justify-between items-start w-full lg:w-2/5 flex-grow-0 flex-shrink-0'>
        <h1 class='text-2xl font-semibold text-gray-900 pl-2 lg:pl-0 my-4'>행사장 오시는 길</h1>
        <img 
          src='https://d3r2ol85dktaw0.cloudfront.net/conferences/ksso2022/images/walkerhill_google_map.png'
          class='image-link block h-auto lg:h-48 w-full lg:w-48 border bg-white hover:shadow-md cursor-pointer'
          @click='imageLink("googleMap")' />
      </div>
      <div>
        <h3 class='text-lg font-semibold mb-2 px-2'>무료 셔틀버스 안내</h3>
        <ul class='list-disc pl-3'>
          <li class='py-1 ml-4'>
            지하철 2호선 강변역 1번 출구 왼쪽 방향, 구의공원 앞 워커힐 무료 셔틀버스 정류장에서 탑승 
            <span class='text-gray-700 text-sm'>(약 15분 소요)</span>
          </li>
          <li class='py-1 ml-4'>
            지하철 5호선 광나루역 2번 출구 앞, 광진구민 체육센터 근처 워커힐 무료 셔틀버스 정류장에서 탑승
            <span class='text-gray-700 text-sm'>(약 8분 소요)</span>
            </li>
          <li class='py-1 ml-4'>배차 간격 15분 / 첫차: 6:00, 막차: 23:30</li>
        </ul>
        <a 
          href='https://www.walkerhill.com/grandwalkerhillseoul/Map' 
          target='_blank' 
          class='flex flex-row items-center justify-start gap-x-2 py-1 px-1 mt-2 hover:bg-gray-100'>
          <globe-alt-icon class='h-5'/>
          <span
            class='inline-block'>
            자세한 사항은 홈페이지를 참고해주시기 바랍니다.
          </span>
        </a>
      </div>
    </section>
    <section class='py-8 flex flex-col lg:flex-row justify-start gap-x-4 items-start'>
      <div class='flex flex-col-reverse lg:flex-row justify-start lg:justify-between items-start w-full lg:w-2/5 flex-grow-0 flex-shrink-0'>
        <h1 class='text-2xl font-semibold text-gray-900 pl-2 lg:pl-0 my-4'></h1>
        <img 
          src='https://d3r2ol85dktaw0.cloudfront.net/conferences/ksso2022/images/shuttle_location_map.png'
          class='image-link block h-auto lg:h-48 w-full lg:w-48 border bg-white hover:shadow-md cursor-pointer'
          @click='imageLink("shuttleLocation")' />
      </div>
      <div>
        <h3 class='text-lg font-semibold mb-2 px-2'>학회 제공 무료 셔틀 추가 안내</h3>
        <ul class='list-disc pl-3'>
          <li class='py-1 ml-4'>
            대한비만학회에서 별도로 광나루역에서 행사장까지 순환하는 셔틀버스를 제공해드립니다.
          </li>
          <li class='py-1 ml-4'>
            지하철 5호선 광나루역 2번 출구 맞은편, 그랜드 워커힐 셔틀버스 정류장에서 탑승
          </li>
          <li class='py-1 ml-4'>
            배차 간격 (재 안내 예정)
          </li>
        </ul>
      </div>
    </section>
    <section class='py-8 flex flex-col lg:flex-row justify-start gap-x-4 items-start'>
      <div class='flex flex-col-reverse lg:flex-row justify-start lg:justify-between items-start w-full lg:w-2/5 flex-grow-0 flex-shrink-0'>
        <h1 class='text-2xl font-semibold text-gray-900 pl-2 lg:pl-0 my-4'>행사장 도면</h1>
        <img 
          src='https://d3r2ol85dktaw0.cloudfront.net/conferences/ksso2022/images/walkerhill_hall_map.jpg'
          class='image-link block h-auto lg:h-48 w-full lg:w-48 border bg-white hover:shadow-md cursor-pointer'
          @click='imageLink("hallMap")' />
      </div>
      <p class='px-2'>그랜드워커힐서울 비스타홀</p>
      
    </section>
  </div>
</template>

<script>
import { GlobeAltIcon, PhoneIcon, LocationMarkerIcon } from '@vue-hero-icons/outline'

export default {
  name: 'EventLocation',
  components: {
    GlobeAltIcon,
    PhoneIcon,
    LocationMarkerIcon,
  },
  methods: {
    imageLink (link) {
      let url = ''
      if (link === 'googleMap') {
        url = 'https://www.google.com/maps/place/Grand+Walkerhill+Seoul/@37.5512228,127.1027551,15z/data=!4m8!3m7!1s0x357cbaba125163d7:0xac346f03d617148e!5m2!4m1!1i2!8m2!3d37.5552126!4d127.1108905'
      } else if (link === 'hallMap') {
        url = 'https://www.walkerhill.com/assets/grandwalkerhillseoul/global/images/etc/fig_vistaM.jpg'
      } else if (link ===  'shuttleLocation') {
        url = 'https://d3r2ol85dktaw0.cloudfront.net/conferences/ksso2022/images/shuttle_location_map.png'
      } else if (link === 'logo') {
        url = 'https://www.walkerhill.com/grandwalkerhillseoul/'
      } else {
        url = 'https://www.walkerhill.com/grandwalkerhillseoul/'
      }
      window.open(url, '_blank')
    }
  }
}
</script>

<style lang='scss' scoped>
  .img-link {
    transition: all 0.15s ease-in-out;
  }
</style>

